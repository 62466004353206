import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const tostetudPorandad = () => (
  <Layout>
    <SEO title="Tõstetud põrandad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>MOODULPÕRAND</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/tostetudporandad/alajaamade-susteem/">
          <div className="epo">
            <h2>Alajaamade süsteem</h2>
          </div>
        </Link>
        <Link to="/tostetudporandad/kaltsiumsulfaat-paneelid/">
          <div className="epo">
            <h2>Kaltsiumsulfaat-paneelid</h2>
          </div>
        </Link>
        <Link to="/tostetudporandad/puitlaastpaneelid/">
          <div className="epo">
            <h2>Puitlaastpaneelid</h2>
          </div>
        </Link>
      </div>

      <div className="tostetudporandad-wrapper">
        <div className="tostetudporandad-text">
          <h3>Moodulpõrand</h3>
          <p>
            Tõstetud põrandate (moodulpõrandate) süsteem on vajalik
            büroopindadel (ka alajaamadel, tehnilistel ruumidel, serveriruumidel
            jne), kus tehnika rohkuse tõttu on mõistlik juhtmed peita põranda
            alla. Samuti on tõstetud põrand abiks seal, kus ei ole võimalik
            põrandapinda kergitada betoonitööde abil ning seadmete
            ventileerimiseks ja jahutamiseks põranda alt. Põrandapinda on
            võimalik tõsta kuni 2 m kõrgusele. Soovi korral paigaldame
            põrandaplaadile Teile sobiva põrandakatte (PVC, kumm, parkett,
            looduslik kivi jpm). Tellida saab ka ilma põrandakatteta põrandat.
          </p>
        </div>
        <div className="subpage-img tostetudporandad-img"></div>
      </div>
    </div>
  </Layout>
)

export default tostetudPorandad
